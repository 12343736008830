<template>
    <section class="viviendasIndex ml-4 p-4">
        <tabs :tabs="arrayTabs" />
        <router-view class="mt-4" @refrescar="listarIndicadores" />
    </section>
</template>

<script>
import Viviendas from '~/services/viviendas'

export default {
    components:{
    // modalRegistrarPago: () => import('./partials/modalRegistrarPago')
    },
    data(){
        return{
            inputSearch:'',
            arrayTabs: [
                {
                    titulo:'Recibos de pago',
                    ruta:"pagos.admin.recibo.pagos",
                },
                {
                    titulo:'Registro de pagos',
                    ruta:"pagos.admin.registro.pagos",
                    // num: 1
                },
            ],
        }
    },
    mounted(){
        // this.listarIndicadores()
    },
    methods:{
        async listarIndicadores(){
            try {
                const { data } = await Viviendas.indicadoresGenerales()
                this.arrayTabs[2].num = data.solicitudes
            } catch (error){
                this.errorCatch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    
</style>